export class SliderModel {
    private _line: HTMLElement;
    private _prev: HTMLElement;
    private _next: HTMLElement;

    private _posX1: number = 0;
    private _posX2: number = 0;
    private _posInitial: number = 0;
    private _posFinal: number = 0;
    private _threshold: number = 100;

    private _slides: HTMLElement[];
    private _firstSlide: HTMLElement;
    private _lastSlide: HTMLElement;
    private _cloneFirst: HTMLElement;
    private _cloneLast: HTMLElement;

    private _index: number = 0;
    private _allowShift: boolean = true;

    private _slidesCount: number;
    private _slideSize: number;

    private _offset: number = 0;

    public constructor(line: HTMLElement, prev: HTMLElement, next: HTMLElement) {
        this._line = line;
        this._prev = prev;
        this._next = next;

        this._slides = Array.from<HTMLElement>(line.querySelectorAll('[data-slide]'));
        this._firstSlide = this._slides[0];
        this._lastSlide = this._slides[this._slides.length - 1];
        this._cloneFirst = this._firstSlide.cloneNode(true) as HTMLElement;
        this._cloneLast = this._lastSlide.cloneNode(true) as HTMLElement;

        this._slidesCount = this._slides.length;
        this._slideSize = this._firstSlide.offsetWidth;

        // Clone first and last slide
        this._line.appendChild(this._cloneFirst);
        this._line.insertBefore(this._cloneLast, this._firstSlide);

        this._setOffset(-this._slideSize);

        // Mouse and Touch events
        this._line.addEventListener('mousedown', this._dragStart);

        // Touch events
        this._line.addEventListener('touchstart', this._dragStart);

        // Transition events
        this._line.addEventListener('transitionend', this._onTransitionEnd, { passive: true });

        // Click events
        this._prev.addEventListener('click', this._toPrev, { passive: true });
        this._next.addEventListener('click', this._toNext, { passive: true });

        this._checkActive();
    }

    public destroy(): void {
        this._cloneFirst.remove();
        this._cloneLast.remove();

        this._line.removeEventListener('mousedown', this._dragStart);
        this._line.removeEventListener('touchstart', this._dragStart);
        this._line.removeEventListener('transitionend', this._onTransitionEnd);

        this._prev.removeEventListener('click', this._toPrev);
        this._next.removeEventListener('click', this._toNext);

        document.removeEventListener('touchend', this._dragEnd);
        document.removeEventListener('touchmove', this._dragAction);
        document.removeEventListener('mouseup', this._dragEnd);
        document.removeEventListener('mousemove', this._dragAction);
    }

    private _dragStart = (e: any) => {
        e.preventDefault();
        this._posInitial = this._getOffset();

        if (e.type === 'touchstart') {
            this._posX1 = e.touches[0].clientX;
            document.addEventListener('touchend', this._dragEnd, { passive: true });
            document.addEventListener('touchmove', this._dragAction, { passive: true });
        } else {
            this._posX1 = e.clientX;
            document.addEventListener('mouseup', this._dragEnd, { passive: true });
            document.addEventListener('mousemove', this._dragAction, { passive: true });
        }
    };

    private _dragAction = (e: any) => {
        if (e.type === 'touchmove') {
            this._posX2 = this._posX1 - e.touches[0].clientX;
            this._posX1 = e.touches[0].clientX;
        } else {
            this._posX2 = this._posX1 - e.clientX;
            this._posX1 = e.clientX;
        }

        this._setOffset(this._getOffset() - this._posX2);
    };

    private _dragEnd = () => {
        this._posFinal = this._getOffset();
        if (this._posFinal - this._posInitial < -this._threshold) {
            this._shiftSlide(1, true);
        } else if (this._posFinal - this._posInitial > this._threshold) {
            this._shiftSlide(-1, true);
        } else {
            this._setOffset(this._posInitial);
        }

        document.removeEventListener('touchend', this._dragEnd);
        document.removeEventListener('touchmove', this._dragAction);
        document.removeEventListener('mouseup', this._dragEnd);
        document.removeEventListener('mousemove', this._dragAction);
    };

    private _shiftSlide(dir: number, isDrag?: boolean) {
        this._line.classList.add('shifting');

        if (this._allowShift) {
            if (!isDrag) {
                this._posInitial = this._getOffset();
            }

            if (dir === 1) {
                this._setOffset(this._posInitial - this._slideSize);
                this._index++;
            } else if (dir === -1) {
                this._setOffset(this._posInitial + this._slideSize);

                this._index--;
            }

            this._checkActive();
        }

        this._allowShift = false;
    }

    private _onTransitionEnd = (e: TransitionEvent) => {
        if (e.target !== this._line) {
            return;
        }

        if (this._index == -1) {
            this._setOffset(-(this._slidesCount * this._slideSize));
            this._index = this._slidesCount - 1;
        }

        if (this._index == this._slidesCount) {
            this._setOffset(-(1 * this._slideSize));
            this._index = 0;
        }

        this._checkActive();
        this._allowShift = true;

        this._line.classList.remove('shifting');
    };

    private _checkActive = () => {
        this._cloneFirst.classList.remove('active');
        this._cloneLast.classList.remove('active');

        for (let i = 0; i < this._slides.length; i++) {
            this._slides[i].classList.toggle('active', i === this._index);
        }

        if (this._index == -1) {
            this._cloneLast.classList.add('active');
            this._lastSlide.classList.add('active');
            return;
        }

        if (this._index == this._slidesCount) {
            this._cloneFirst.classList.add('active');
            this._firstSlide.classList.add('active');
            return;
        }
    };

    private _setOffset = (v: number) => {
        this._offset = v;
        this._line.style.transform = `translateX(${v}px)`;
    };

    private _getOffset = () => {
        return this._offset;
    };

    private _toPrev = () => this._shiftSlide(-1);
    private _toNext = () => this._shiftSlide(1);
}
