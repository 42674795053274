import React, { useRef } from 'react';
import { Helmet } from 'react-helmet';
import { graphql, PageProps, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import { useLax } from 'utils/hooks/lax';
import { twLink } from 'constants/constants';
import { fromHtmlEntities } from 'utils/string-helper';
import { Card, CardBody, CardBodyImage, CardBodyLogo, CardImage, Cards, ArrowSmall, CardGreenArrow, CirclesBackground } from 'components';
import { Citation, CitationAuthor, CitationQuote } from 'components/citation/citation';
import { Contacts, ContactsItem } from 'components/contacts/contacts';
import {
    MainHero,
    MainHeroBody,
    MainHeroDescription,
    MainHeroFooter,
    MainHeroIcon,
    MainHeroImage,
    MainHeroTitle,
} from 'components/main-hero/main-hero';
import {
    Post,
    PostBody,
    PostContainer,
    PostFooter,
    PostPicture,
    Posts,
    PostsDescription,
    PostsHeader,
    PostsList,
    PostsTitle,
    PostUser,
} from 'components/posts/posts';
import { Slide, Slider, SliderContainer, SliderTitle } from 'components/slider/slider';
import { Story, StoryDescription, StoryLink, StoryParagraph, StoryTitle } from 'components/story/story';
import { ArrowDownIcon } from 'components/svg/arrow-down';
import { QuotesIcon } from 'components/svg/quotes';
import UnicheckLogoSVG from '../img/unicheck/unicheck-logo.svg';
import LoioLogoSVG from '../img/loio/loio-logo.svg';

export default function MainPage(props: PageProps): JSX.Element {
    const { path } = props;

    const data = useStaticQuery(imgQuery);

    useLax();

    const getFade = (name: string) => {
        if (path.match('/job')) {
            return '';
        }

        return name;
    };

    return (
        <>
            <Helmet>
                <title>Phase One Karma — Creating genuine smartness through AI technologies.</title>
                <meta name="description"
                      content="Phase One Karma is a human-centered software product company focused on launching meaningful AI technologies and innovations."
                />
              <meta property="og:title" content="Phase One Karma — Creating genuine smartness through AI technologies." />
            </Helmet>
            <MainHero id="home">
                <MainHeroImage
                    data-lax
                    data-lax-opacity="0 1, 200 1, 400 0"
                    data-lax-translate-y="0 0, 400 -100"
                >
                    <Img fluid={data.heroClouds1.childImageSharp.fluid}
                         fadeIn={false}
                         style={{ position: 'absolute' }}
                         alt="Clouds"
                         title="Hero clouds"
                    />
                    <Img fluid={data.heroClouds2.childImageSharp.fluid}
                         fadeIn={false}
                         style={{ position: 'absolute' }}
                         alt="Clouds"
                         title="Hero clouds"
                    />
                    <Img fluid={data.heroPapers.childImageSharp.fluid}
                         fadeIn={false}
                         style={{ position: 'absolute' }}
                         alt="Clouds"
                         title="Hero clouds"
                    />
                    <Img fluid={data.heroPhone.childImageSharp.fluid} fadeIn={false} style={{ position: 'absolute' }} />
                    <Img fluid={data.heroSkate.childImageSharp.fluid} fadeIn={false} style={{ position: 'absolute' }} />
                    <Img fluid={data.heroGirl.childImageSharp.fluid} fadeIn={false} style={{ position: 'absolute' }} />
                    <Img fluid={data.heroMan1.childImageSharp.fluid} fadeIn={false} style={{ position: 'absolute' }} />
                    <Img fluid={data.heroMan2.childImageSharp.fluid} fadeIn={false} style={{ position: 'absolute' }} />
                </MainHeroImage>
                <MainHeroBody data-lax data-lax-opacity="0 1, 200 1, 400 0" data-lax-translate-y="0 0, 400 -100">
                    <MainHeroTitle
                        data-aos={getFade("fade-down")}
                        data-aos-offset="200"
                        data-aos-delay={60 * 1}
                        data-aos-anchor="#main"
                    >
                        to the point<em>.</em>
                    </MainHeroTitle>
                    <MainHeroDescription
                        data-aos={getFade("fade-down")}
                        data-aos-offset="200"
                        data-aos-delay={60 * 2}
                        data-aos-anchor="#main"
                    >
                        we create software products that have a point and a meaningful impact
                    </MainHeroDescription>
                    <MainHeroIcon
                        data-aos={getFade("fade-down")}
                        data-aos-offset="200"
                        data-aos-delay={60 * 3}
                        data-aos-anchor="#main"
                    >
                        <ArrowDownIcon />
                    </MainHeroIcon>
                </MainHeroBody>
                <MainHeroFooter>
                    <Contacts>
                        <ContactsItem
                            label="collaborate"
                            to="mailto:letscollaborate@p1k.org"
                            data-aos={getFade('fade-down')}
                            data-aos-offset="200"
                            data-aos-delay={60 * 4}
                            data-aos-anchor="#main"
                        >
                            → letscollaborate@p1k.org
                        </ContactsItem>
                        <ContactsItem
                            label="follow"
                            to={twLink}
                            data-aos={getFade('fade-down')}
                            data-aos-offset="200"
                            data-aos-delay={60 * 5}
                            data-aos-anchor="#main"
                        >
                            → tw: @phase1karma
                        </ContactsItem>
                        <ContactsItem
                            label="talk"
                            to="mailto:letstalk@p1k.org"
                            data-aos={getFade('fade-down')}
                            data-aos-offset="200"
                            data-aos-delay={60 * 6}
                            data-aos-anchor="#main"
                        >
                            → letstalk@p1k.org
                        </ContactsItem>
                    </Contacts>
                </MainHeroFooter>
            </MainHero>


            <Cards>
                <Card isBig to="/mission" data-aos="fade-up" data-aos-offset="200" data-aos-delay={60}>
                    <CardBody>
                        <CardImage>
                            <Img fluid={data.card1.childImageSharp.fluid} />
                        </CardImage>

                        <h3>We are bigger than just a product company. We are a value-driven community.</h3>
                        <p>Read about what is beyond the products in Phase One Karma.</p>
                        <ArrowSmall style={{ marginTop: '16px' }} />
                    </CardBody>
                </Card>
                <Card to="/unicheck" data-aos="fade-right" data-aos-offset="200" data-aos-delay={60}>
                    <CardBody withImage>
                        <CardBodyLogo>
                            <img src={UnicheckLogoSVG} alt="Unicheck" />
                        </CardBodyLogo>
                        <h3>Tackling academic dishonesty with Unicheck</h3>
                        <p>AI-driven anti-plagiarism checker with more than 1 million users worldwide.</p>

                        <CardBodyImage>
                            <Img fluid={data.unicheckProjects.childImageSharp.fluid} />
                        </CardBodyImage>

                        <CardGreenArrow />
                    </CardBody>
                </Card>
                <Card to="/loio" color="gray" data-aos="fade-left" data-aos-offset="200" data-aos-delay={60}>
                    <CardBody withImage>
                        <CardBodyLogo>
                            <img src={LoioLogoSVG} alt="Loio" title="Loio" />
                        </CardBodyLogo>
                        <h3>Loio — a shortcut to perfect legal documents</h3>
                        <p>Advanced styling, comparison, and AI-analysis for legal contracts in one add-in.</p>

                        <CardBodyImage>
                            <Img fluid={data.loioProjects.childImageSharp.fluid} alt="Loio" title="Loio" />
                        </CardBodyImage>

                        <CardGreenArrow />
                    </CardBody>
                </Card>
            </Cards>

            <Story>
                <StoryTitle data-aos={getFade('fade-up')} data-aos-offset="200" data-aos-delay={60}>
                    there is a story to how phase one karma got where it is.
                </StoryTitle>
                <StoryDescription>
                    <StoryParagraph
                        data-aos={getFade('fade-right')}
                        data-aos-offset="200"
                        data-aos-delay={60 * 2}
                        data-aos-anchor="#history"
                    >
                        In 2014, not long after we had finished universities and had gotten into the tech world, we had
                        an idea. There were ways in which artificial intelligence could help universities become more
                        transparent — and we knew how to apply them. It was a match, and it turned into Unicheck.
                    </StoryParagraph>
                    <StoryParagraph
                        data-aos={getFade('fade-left')}
                        data-aos-offset="200"
                        data-aos-delay={60 * 2}
                        data-aos-anchor="#history"
                    >
                        As more amazing people were joining us and our product was growing, we realized: hey, we are
                        good at this. That is when Phase One Karma was born — a company that would create tech products
                        and transforms state-of-the-art technologies into something meaningful.
                    </StoryParagraph>
                    <StoryLink
                        to="/history"
                        data-aos={getFade('fade-left')}
                        data-aos-offset="200"
                        data-aos-delay={60 * 2}
                        data-aos-anchor="#history"
                    >
                        read our full story
                    </StoryLink>
                </StoryDescription>
            </Story>


            <Slider>
                <SliderTitle id="team" data-aos={getFade('fade-up')} data-aos-offset="200" data-aos-delay={60}>
                    meet <em>the</em> team
                </SliderTitle>
                <SliderContainer
                    data-aos={getFade('fade')}
                    data-aos-offset="200"
                    data-aos-delay={60 * 2}
                    data-aos-anchor="#team"
                >
                    <Slide data-slide>
                        <Img fluid={data.slide1.childImageSharp.fluid} loading="lazy" fadeIn={false} />
                    </Slide>
                    <Slide data-slide>
                        <Img fluid={data.slide2.childImageSharp.fluid} loading="lazy" fadeIn={false} />
                    </Slide>
                    <Slide data-slide>
                        <Img fluid={data.slide3.childImageSharp.fluid} loading="lazy" fadeIn={false} />
                    </Slide>
                    <Slide data-slide>
                        <Img fluid={data.slide4.childImageSharp.fluid} loading="lazy" fadeIn={false} />
                    </Slide>
                </SliderContainer>
            </Slider>

            <CirclesBackground>
                <Citation>
                    <div data-aos={getFade('fade-up')} data-aos-offset="200" data-aos-delay={60}>
                        <QuotesIcon />
                    </div>

                    <CitationQuote data-aos={getFade('fade-up')} data-aos-offset="200" data-aos-delay={60 * 2}>
                        We believe all our work should be fun, meaningful, and to the point.
                    </CitationQuote>
                    <CitationAuthor data-aos={getFade('fade-up')} data-aos-offset="200" data-aos-delay={60 * 3}>
                        <span>Artem Fenkovskyi</span>
                        <span>Chief Operating Officer</span>
                    </CitationAuthor>
                </Citation>


                <Posts>
                    <PostsHeader>
                        <PostsTitle id="blog" data-aos={getFade('fade-up')} data-aos-offset="200" data-aos-delay={60}>
                            points <em>of</em> insight
                        </PostsTitle>
                        <PostsDescription data-aos={getFade('fade-up')} data-aos-offset="200" data-aos-delay={60 * 2}>
                            Read our latest blog posts and gain insights into the world of tech
                        </PostsDescription>
                    </PostsHeader>
                    <PostsList>
                        {data.posts.nodes.map((p: any, i: number) => (
                            <Post key={i}
                                  data-view={['main', 'secondary', 'secondary'][i]}
                                  href={p.link}
                                  data-aos={getFade('fade-up')}
                                  data-aos-offset="200"
                                  data-aos-delay={60 * i}
                                  data-aos-anchor="#blog"
                            >
                                {p.fimg_url && <LazyPostPicture src={p.fimg_url} />}
                                <PostContainer>
                                    <PostBody>
                                        {i !== 0 && <time>{p.date}</time>}
                                        <span>{fromHtmlEntities(p.title)}</span>
                                        {i === 1 && (
                                            <p title={p.excerpt.replace(/<\/?[^>]+(>|$)/g, '')}
                                               dangerouslySetInnerHTML={{
                                                   __html: p.excerpt.replace(/<\/?[^>]+(>|$)/g, '').slice(0, 126) + '...',
                                               }}
                                            />
                                        )}
                                    </PostBody>
                                    <PostFooter>
                                        <PostUser>
                                            {p.author_meta.avatar && (
                                                <img src={p.author_meta.avatar} alt={p.author_meta.name} width={50} />
                                            )}
                                            <span>{p.author_meta.name}</span>
                                            <em>{p.author_meta.description}</em>
                                        </PostUser>
                                        <ArrowSmall />
                                    </PostFooter>
                                </PostContainer>
                            </Post>
                        ))}
                    </PostsList>
                </Posts>
            </CirclesBackground>
        </>
    );
}

function LazyPostPicture(props: { src: string }) {
    const { src } = props;

    const ref = useRef<HTMLDivElement>(null);

    React.useEffect(() => {
        if (ref.current === null) {
            return;
        }

        const io = new IntersectionObserver(([e]) => {
            if (ref.current && (e.isIntersecting || e.intersectionRatio > 0)) {
                ref.current.style.backgroundImage = `url(${src})`;
                io.disconnect();
            }
        });

        io.observe(ref.current);

        return () => io.disconnect();
    }, [src]);

    return <PostPicture ref={ref} style={{ backgroundSize: 'cover' }} />;
}

export const imgQuery = graphql`
    query {
        posts: allWordpressPost(limit: 3) {
            nodes {
                title
                excerpt
                link
                date(formatString: "MMMM DD, YYYY")
                fimg_url
                author_meta {
                    avatar
                    description
                    name
                }
            }
        }

        unicheckProjects: file(relativePath: { eq: "unicheck/unicheck-1.png" }) {
            childImageSharp {
                fluid(maxWidth: 720, srcSetBreakpoints: [720], quality: 95) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                }
            }
        }
        loioProjects: file(relativePath: { eq: "loio/loio-1.png" }) {
            childImageSharp {
                fluid(maxWidth: 720, srcSetBreakpoints: [720], quality: 95) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                }
            }
        }
        
        
        heroClouds1: file(relativePath: { eq: "home/hero/clouds-1.png" }) {
            childImageSharp {
                fluid(maxWidth: 1855, srcSetBreakpoints: [1855], quality: 90) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                }
            }
        }
        heroClouds2: file(relativePath: { eq: "home/hero/clouds-2.png" }) {
            childImageSharp {
                fluid(maxWidth: 738, srcSetBreakpoints: [738], quality: 95) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                }
            }
        }
        heroPapers: file(relativePath: { eq: "home/hero/papers.png" }) {
            childImageSharp {
                fluid(maxWidth: 1855, srcSetBreakpoints: [1855], quality: 95) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                }
            }
        }
        heroPhone: file(relativePath: { eq: "home/hero/phone.png" }) {
            childImageSharp {
                fluid(maxWidth: 738, srcSetBreakpoints: [738], quality: 95) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                }
            }
        }
        heroSkate: file(relativePath: { eq: "home/hero/skate.png" }) {
            childImageSharp {
                fluid(maxWidth: 738, srcSetBreakpoints: [738], quality: 95) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                }
            }
        }
        heroGirl: file(relativePath: { eq: "home/hero/girl.png" }) {
            childImageSharp {
                fluid(maxWidth: 738, srcSetBreakpoints: [738], quality: 95) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                }
            }
        }
        heroMan1: file(relativePath: { eq: "home/hero/man-1.png" }) {
            childImageSharp {
                fluid(maxWidth: 738, srcSetBreakpoints: [738], quality: 95) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                }
            }
        }
        heroMan2: file(relativePath: { eq: "home/hero/man-2.png" }) {
            childImageSharp {
                fluid(maxWidth: 738, srcSetBreakpoints: [738], quality: 95) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                }
            }
        }
        card1: file(relativePath: { eq: "home/card-1.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 1008, srcSetBreakpoints: [1008], quality: 95) {
                    ...GatsbyImageSharpFluid_withWebp_tracedSVG
                }
            }
        }
        card2: file(relativePath: { eq: "home/card-2.png" }) {
            childImageSharp {
                fluid(maxWidth: 1128, srcSetBreakpoints: [1128], quality: 95) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                }
            }
        }
        slide1: file(relativePath: { eq: "team/slide-1.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 1216, srcSetBreakpoints: [768], quality: 95) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        slide2: file(relativePath: { eq: "team/slide-2.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 1216, srcSetBreakpoints: [768], quality: 95) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        slide3: file(relativePath: { eq: "team/slide-3.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 1216, srcSetBreakpoints: [768], quality: 95) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        slide4: file(relativePath: { eq: "team/slide-4.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 1216, srcSetBreakpoints: [768], quality: 95) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        post1: file(relativePath: { eq: "home/post-1.png" }) {
            childImageSharp {
                fluid(quality: 95) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        post2: file(relativePath: { eq: "home/post-2.png" }) {
            childImageSharp {
                fluid(quality: 95) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        post3: file(relativePath: { eq: "home/post-3.png" }) {
            childImageSharp {
                fluid(quality: 95) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
    }
`;
