import React from 'react';

export function QuotesIcon(): JSX.Element {
    return (
        <svg width="50" height="39" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M22.7 28.29c0 6.08-4.6 10.67-11.21 10.67C4.59 38.96 0 34.37 0 27.75a17 17 0 01.68-4.46c.84-2.43 1.52-3.54 3.13-6.17l1.6-2.61L14.05.86c.68-.95 1.36-1.08 2.17-.54l4.05 2.56c.95.68 1.08 1.36.4 2.3l-10 13.78.63-.09c.64-.1 1.16-.18 1.67-.18 5.54 0 9.73 3.65 9.73 9.6zm27.3 0c0 6.08-4.6 10.67-11.22 10.67-6.62 0-11.35-4.59-11.35-11.21a17 17 0 01.68-4.46c.84-2.43 1.52-3.54 3.14-6.17l1.59-2.61L41.35.86c.54-.81 1.22-.95 2.16-.4l3.92 2.42c.95.68 1.22 1.36.54 2.3l-10 13.78.63-.09c.64-.1 1.15-.18 1.67-.18 5.81 0 9.73 3.65 9.73 9.6z"
                fill="#000"
            />
        </svg>
    );
}
