import React, { PropsWithChildren } from 'react';

import { ArrowSmall } from 'components/arrow/arrow';
import { Grid } from 'components/grid/grid';

import styles from './story.module.scss';

export function Story(props: PropsWithChildren<{}>): JSX.Element {
    return (
        <Grid>
            <div className={styles.story} {...props} />
        </Grid>
    );
}

export function StoryTitle(props: PropsWithChildren<{ id?: string }>): JSX.Element {
    return <span className={styles.story__title} {...props} />;
}

export function StoryDescription(props: PropsWithChildren<{}>): JSX.Element {
    return <div className={styles.story__description} {...props} />;
}

export function StoryParagraph(props: PropsWithChildren<{}>): JSX.Element {
    return <p className={styles.story__paragraph} {...props} />;
}

export function StoryLink(props: PropsWithChildren<{ to: string }>): JSX.Element {
    const { to, children, ...restProps } = props;
    return (
        <a href={to} className={styles.story__link} {...restProps}>
            <span>{children}</span>
            <ArrowSmall />
        </a>
    );
}
