import React, { HTMLProps, PropsWithChildren } from 'react';

import { Grid } from 'components/grid/grid';

import styles from './main-hero.module.scss';

export function MainHero(props: PropsWithChildren<{ id?: string }>): JSX.Element {
    return (
        <Grid>
            <div className={styles.mainHero} {...props} />
        </Grid>
    );
}

export function MainHeroImage(props: PropsWithChildren<HTMLProps<HTMLDivElement>>): JSX.Element {
    return <div className={styles.mainHero__image} {...props} />;
}

export function MainHeroBody(props: PropsWithChildren<HTMLProps<HTMLDivElement>>): JSX.Element {
    return <div className={styles.mainHero__body} {...props} />;
}

export function MainHeroFooter(props: PropsWithChildren<{}>): JSX.Element {
    return <div className={styles.mainHero__footer} {...props} />;
}

export function MainHeroTitle(props: PropsWithChildren<HTMLProps<HTMLSpanElement>>): JSX.Element {
    return <span className={styles.mainHero__title} {...props} />;
}

export function MainHeroDescription(props: PropsWithChildren<HTMLProps<HTMLSpanElement>>): JSX.Element {
    return <span className={styles.mainHero__description} {...props} />;
}

export function MainHeroIcon(props: PropsWithChildren<{}>): JSX.Element {
    return <div className={styles.mainHero__icon} {...props} />;
}
