import React, { PropsWithChildren } from 'react';

import { Grid } from 'components/grid/grid';

import styles from './citation.module.scss';

export function Citation(props: PropsWithChildren<{}>) {
    return (
        <Grid>
            <div className={styles.citation} {...props} />
        </Grid>
    );
}

export function CitationQuote(props: PropsWithChildren<{}>) {
    return <span className={styles.citation__quote} {...props} />;
}

export function CitationAuthor(props: PropsWithChildren<{}>) {
    return <div className={styles.citation__author} {...props} />;
}
