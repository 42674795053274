import React, { PropsWithChildren, useEffect, useRef } from 'react';
import cn from 'classnames';

import { ArrowBig } from 'components/arrow/arrow';

import { SliderModel } from './slider-model';

import styles from './slider.module.scss';

export function Slider(props: PropsWithChildren<{ id?: string }>): JSX.Element {
    return <div className={styles.slider} {...props} />;
}

export function SliderTitle(props: PropsWithChildren<{ id?: string }>): JSX.Element {
    return <span className={styles.slider__title} {...props} />;
}

export function SliderContainer(props: PropsWithChildren<{}>): JSX.Element | null {
    const { children, ...restProps } = props;

    const lineRef = useRef<HTMLDivElement>(null);
    const prevRef = useRef<HTMLButtonElement>(null);
    const nextRef = useRef<HTMLButtonElement>(null);

    useEffect(() => {
        const lineEl = lineRef.current;
        const prevEl = prevRef.current;
        const nextEl = nextRef.current;

        if (lineEl === null || prevEl === null || nextEl === null) {
            return;
        }

        let sliderModel = new SliderModel(lineEl, prevEl, nextEl);

        const onResize = () => {
            sliderModel.destroy();
            sliderModel = new SliderModel(lineEl, prevEl, nextEl);
        };
        window.addEventListener('resize', onResize, { passive: true });

        return () => {
            window.removeEventListener('resize', onResize);
            sliderModel.destroy();
        };
    }, [lineRef, prevRef, nextRef]);

    return (
        <div className={styles.slider__container} {...restProps}>
            <div ref={lineRef} className={styles.slider__line}>
                {children}
            </div>
            <button ref={prevRef} title="Previous" className={cn(styles.slider__control, styles.slider__controlLeft)}>
                <ArrowBig />
            </button>
            <button ref={nextRef} title="Next" className={cn(styles.slider__control, styles.slider__controlRight)}>
                <ArrowBig />
            </button>
        </div>
    );
}

export function Slide(props: PropsWithChildren<{ active?: boolean }>): JSX.Element {
    const { active, ...restProps } = props;
    return <div className={cn(styles.slide, { [styles.slideActive]: active })} {...restProps} />;
}
