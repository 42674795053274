import React, { CSSProperties, forwardRef, PropsWithChildren, Ref } from 'react';

import { Grid } from 'components/grid/grid';

import styles from './posts.module.scss';

export function Posts(props: PropsWithChildren<{}>): JSX.Element {
    return (
        <Grid>
            <div className={styles.posts} {...props} />
        </Grid>
    );
}

export function PostsHeader(props: PropsWithChildren<{}>): JSX.Element {
    return <div className={styles.posts__header} {...props} />;
}

export function PostsTitle(props: PropsWithChildren<{ id?: string }>): JSX.Element {
    return <span className={styles.posts__title} {...props} />;
}

export function PostsDescription(props: PropsWithChildren<{}>): JSX.Element {
    return <span className={styles.posts__description} {...props} />;
}

export function PostsList(props: PropsWithChildren<{}>): JSX.Element {
    return <div className={styles.posts__list} {...props} />;
}

export function Post(props: PropsWithChildren<{ style?: CSSProperties; href: string }>): JSX.Element {
    const { children, ...restProps } = props;
    return (
        <a className={styles.post} {...restProps}>
            {children}
        </a>
    );
}

export const PostPicture = forwardRef(
    (props: PropsWithChildren<{ style?: CSSProperties }>, ref: Ref<HTMLDivElement>): JSX.Element => {
        return <div ref={ref} className={styles.post__picture} {...props} />;
    },
);

export function PostContainer(props: PropsWithChildren<{ style?: CSSProperties }>): JSX.Element {
    return <div className={styles.post__container} {...props} />;
}

export function PostBody(props: PropsWithChildren<{ style?: CSSProperties }>): JSX.Element {
    return <div className={styles.post__body} {...props} />;
}

export function PostFooter(props: PropsWithChildren<{}>): JSX.Element {
    return <div className={styles.post__footer} {...props} />;
}

export function PostUser(props: PropsWithChildren<{}>): JSX.Element {
    return <div className={styles.post__user} {...props} />;
}
